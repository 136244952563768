.splitLeft {
    height: 90vh;
    width: 30%;
    position: absolute;
    padding: 60px;
    text-align: center;
    align-items: center;
    border-style: inset;
}
.splitRight {
    right: 0;
    height: 90vh;
    width: 70%;
    position: absolute;
    padding: 100px;
    text-align: center;
    align-items: center;
    border-style: inset;
}
