.directionpage{
    width:100%;
    height: 100%;
    text-align: center;
}

.direction_detail{
    width: 80%;
    height: 70%;
    overflow-y: auto;
    font-size: larger;
    margin: auto;
    text-align: left;
    margin-bottom: 30px;
}