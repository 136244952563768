.form-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.register-container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: auto;
    width: 57%;
    height: 700px;
    border-radius: 30px;
    box-shadow: 1px 1px 30px #93989d;
}

.register-logo {
    position: relative;
    margin: auto ;
    width: 350px;
    height: 100px;
    margin-bottom: 20px;
}