* {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  
  body {
    padding: 0;
    margin: 0;
  }
  
  #notfound {
    position: relative;
    height: 90vh;
  }
  
  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 40%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  
  .notfound {
    max-width: 500px;
    width: 100%;
    text-align: center;
    line-height: 1.4;
  }
  
  .notfound>div:first-child {
    text-align: center;
    padding-left: 200px;
    margin-bottom: 20px;
    height: 100px;
  }
  
  .notfound .notfound-404 {
    position: absolute;
    width: 100px;
    height: 100px;
    background: #e01818;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 0px 7px #e01818 inset, 0px 0px 0px 13px #fff inset;
            box-shadow: 0px 0px 0px 7px #e01818 inset, 0px 0px 0px 13px #fff inset;
  }
  
  .notfound .notfound-404 h1 {
    color: #fff;
    font-size: 60px;
    margin: 0px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: inline-block;
    height: 60px;
    line-height: 60px;
  }
  
  .notfound p {
    font-size: 18px;
    font-weight: 400;
    color: #222;
    margin-top: 5px;
  }
  
  @media only screen and (max-width: 480px) {
    .notfound {
      padding-left: 15px;
      padding-right: 15px;
    }
    .notfound>div:first-child {
      padding: 0px;
      height: auto;
    }
    .notfound .notfound-404 {
      position: relative;
      margin-bottom: 15px;
    }
    .notfound h2 {
      font-size: 42px;
    }
  }
  