.TOEFL1{
    width: 100vw;
    height: 100vh;
} 

.Instruction{
    width: 60%;
    height: 70%;
    margin: auto;
    font-size: 20px;
    padding: 10px;
    border-color: rgb(167, 139, 139);
    border-style: solid;
    border-width: 3px;
    text-align: unset;
    margin-bottom: 20px;
    overflow: auto;
}

