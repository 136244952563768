.login-form-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.login-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 20px;
  width: 500px;
  height: 400px;
  border-radius: 30px;
  box-shadow: 1px 1px 30px #93989d;
}

.login-logo {
  position: relative;
  margin: auto;
  width: 350px;
  height: 100px;
  margin-bottom: 20px;
}
