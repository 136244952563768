.navbar {
  background-color: #bc1f1f;
  height: 90px;
  width: 100%;
  display: flex;
  padding-left: 30px;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.Logo {
  margin-left: auto;
  background-color: #f5f5f5;
  border-color: #585b5d;
  border-width: 200px;
  padding: 10px;
  height: 90px;
  width: 250px;
}

.nav-menu {
  z-index: 1;
  background-color: #585b5d;
  width: 250px;
  height: 100%;
  box-shadow: 1px 10px 10px #585b5d;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 80px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 22px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #424242;
}

.nav-menu-items {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.nav-logout {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 80px;
  margin-top: auto;
  border-radius: 4px;
  background-color: #bc1f1f;
}

.nav-logout a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 22px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.navbar-toggle {
  background-color: #585b5d;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: start;
  align-items: center;
}



