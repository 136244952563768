/* Split the screen in half */
.split {
  height: 90%;
  width: 50%;
  position: absolute;
  padding: 30px;
  align-items: center;

  border-style: inset;
}
/*overweite for ReactQuill*/
.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
/* Control the left side */
.left {
  left: 0;
  overflow-y: auto;
}

/* Control the right side */
.right {
  text-align: center;
  right: 0;
  overflow-y: auto;
  font-size: 20px;
}
